import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .btn-vagancy {
    border: 1px solid #e8e8e8;
    background: #e6883b;
    padding: 10px 30px;
  }

  .qtd {
    color: #4a4747;
    border: 1px solid #e8e8e8;
    background: #eaeaea;
    padding: 10px 20px;
  }

  .line {
    width: 5px;
    height: 33px;
    border-radius: 10px;
    background: #947200;
  }
  .vagancy-row {
    border-radius: 13px;
    border: 1px solid #d7d7d7;
    background: #fff;
    padding: 16px;
  }

  .in-progress {
    background: #fff5be;
    padding: 3px 10px;
  }

  .finalized {
    background: #ffcabe;
    padding: 3px 10px;
  }
  .ito {
    background: #a1f0d4;
    padding: 3px 10px;
  }
  .bezerra {
    background: #bedcff;
    padding: 3px 10px;
  }

  .edit-delete {
    position: absolute;
    right: -30px;
    top: -82px;
    border-radius: 9px;
    background: #f6f6f6;
    padding: 8px 12px;
    button {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .btn-pagination {
    display: flex;
    width: 37px;
    height: 37px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;

    + .btn-pagination {
      margin-left: 10px;
    }
  }

  .btn-pagination.arrow,
  .btn-pagination.selected,
  .btn-pagination:not(.ellipsis):hover {
    border: 1px solid #707070;
  }

  @media screen and (min-width: 400px) {
    .btn-pagination {
      width: 45px;
      height: 45px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 992px) {
    .btn-pagination {
      width: 64px;
      height: 64px;
      padding: 20px;
      font-size: 18px;

      + .btn-pagination {
        margin-left: 20px;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  background: rgba(0, 0, 0, 0.3);

  p {
    font-weight: 500;
  }

  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .input-radio {
    label {
      margin-left: 16px !important;

      > div {
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;

        > svg {
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
        }
      }

      span {
        line-height: 21px;
      }

      span span {
        color: #aaa;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .box {
    label:not(.checkboxInput-label) span {
      color: #202020;
      font-weight: 500;
      margin-bottom: 8px;
      display: block;
    }

    + label {
      margin-top: 32px;
    }
  }

  label.focused {
    > div {
      border-color: #202020 !important;
    }
  }

  .input-file {
    position: relative;

    .error-box {
      position: absolute;
      bottom: -35px;
    }
  }

  .input-radio {
    span {
      margin-bottom: 0 !important;
    }
  }

  .input-select {
    border-color: #e4e4e4 !important;
    background-color: #fff !important;
    cursor: pointer;
    svg {
      margin-right: 20px;
    }
    > div input {
      cursor: pointer;
      padding: 0 20px;
    }
  }

  .bg-status {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;
    padding: 22px 20px;
  }

  .input-checkbox {
    flex-direction: column;
    justify-content: start;
    align-items: start;

    label {
      margin-left: 0 !important;
      margin-bottom: 16px !important;

      > div {
        min-width: 24px;
        min-height: 24px;
        border-radius: 4px;

        > svg {
          width: 18px;
          height: 18px;
        }
      }

      span {
        line-height: 21px;
      }
      span span {
        color: #aaa;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .input {
    background-color: #fff !important;
  }

  .btns-group button,
  .btns-group a {
    border: 0;
    border-radius: 20px;
    background: #838383;
    min-width: 36px;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
