import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 46px;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;

    .button-show {
      width: 30px;
      font-size: 12px;
      text-align: center;
      background: none;
      border: 0;
      padding: 6px 6px 6px;
      color: #2e90fd !important;
      height: 52px;
      font-weight: bold;
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #777777;
    transition-duration: 0.2s;

    ::placeholder {
      color: #a3a3a3;
    }

    :focus {
      border: none;
      outline: 0;
    }
  }

  svg {
    margin-right: 16px;
    color: #777777;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #ff1a50;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #777777;
      border-color: #f15a29 !important;
    `}
`;
