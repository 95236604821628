import React, { useCallback, useEffect, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { VscInfo } from 'react-icons/vsc';

import { Container, CheckboxInput, Error } from './styles';
import Input from '../Input';

export interface IOption {
  value: string | number;
  label: string;
  selected?: boolean;
}

interface IInputCheckbox {
  type: 'checkbox' | 'radio';
  name: string;
  options: IOption[];
  className?: string;
  onChange?(options: IOption[]): void;
  error?: string;
}

const InputCheckbox: React.FC<IInputCheckbox> = ({
  type,
  name,
  options,
  className,
  onChange,
  error,
}) => {
  const [data, setData] = useState<IOption[]>([]);

  useEffect(() => {
    setData(options);
  }, [options]);

  const handleChange = useCallback(
    (option) => {
      let newData = data.slice();
      const dataIndex = newData.findIndex(
        (optionData) => optionData === option
      );
      if (dataIndex >= 0) {
        if (type === 'radio') {
          newData = newData.map((optionData) => ({
            ...optionData,
            selected: false,
          }));
        }
        newData[dataIndex].selected = !newData[dataIndex].selected;
        setData(newData);
      }
      if (onChange) {
        onChange(newData);
      }
    },
    [data, onChange, type]
  );

  return (
    <>
      <Container className={className}>
        {options.map((option, index) => (
          <CheckboxInput
            htmlFor={`${name}_${index}`}
            selected={option.selected}
            className="checkboxInput-label"
          >
            <div className={option.selected ? 'checked' : ''}>
              {option.selected && <HiCheck size={12} color="#fff" />}
            </div>
            <input
              type={type}
              id={`${name}_${index}`}
              name={type === 'checkbox' ? `${name}_checkbox` : name}
              value={option.value}
              onChange={() => handleChange(option)}
              className="inputData"
            />
            <span className="mb-0">{option.label}</span>
          </CheckboxInput>
        ))}
        <Input type="hide" name={name} className="d-none" />
      </Container>
      {error && (
        <div className="w-100 d-flex align-items-center mt-1 error-box">
          <VscInfo size={24} color="#FF1A50" className="me-1" />
          <Error className="small text-danger error">{error}</Error>
        </div>
      )}
    </>
  );
};

export default InputCheckbox;
