import MaskService from 'react-masked-text/src/mask-service';

interface maskProps {
  kind:
    | 'credit-card'
    | 'cpf'
    | 'cnpj'
    | 'zip-code'
    | 'only-numbers'
    | 'money'
    | 'cel-phone'
    | 'datetime'
    | 'custom';
  value: string;
  options?: {
    mask?: string;
    format?: string;
    unit?: string;
    separator?: string;
    delimiter?: string;
  };
}

export default function mask({ kind, value, options }: maskProps): string {
  const maskedValue = MaskService.toMask(kind, value, options);

  return maskedValue;
}
