import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiPlus } from 'react-icons/fi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { GrDocument } from 'react-icons/gr';
import { RiArrowLeftSLine, RiArrowRightSLine, RiEyeLine } from 'react-icons/ri';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { format, parseISO } from 'date-fns';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import Input from '~/components/Input';
import Select from '~/components/Select';
import Textarea from '~/components/Textarea';
import InputRadio from '~/components/InputRadio';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';
import Toast from '~/utils/toast';
import { useResize } from '~/hooks/Resize';
import NoData from '~/components/NoData';

interface INursery {
  id: number;
  name: string;
}

interface IJobNursery {
  id: number;
  nursery: INursery;
}

interface IResume {
  id: number;
  name: string;
  archive: {
    name: string;
    archive_url: string;
  };
}

interface IJob {
  id: number;
  title: string;
  requirements: string;
  description: string;
  status: string;
  created_at: string;
  jobs_nurseries: IJobNursery[];
  resumes: IResume[];
}

interface IJobResponse {
  data: IJob[];
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface IVagancy {
  id: number;
  title: string;
  status: 'Em andamento' | 'Finalizado';
  created_at: string;
  nurseries: INursery[];
}

const WorkUs: React.FC = () => {
  const { width } = useResize();
  const formRef = useRef<FormHandles>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [vagancy, setVagancy] = useState<IVagancy[]>([]);
  const [vagancySelected, setVagancySelected] = useState({} as IJob);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [nurseries, setNurseries] = useState<IOption[]>([]);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState('');
  const [pageSelected, setPageSelected] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleLoadJobs = useCallback(async (page) => {
    const response = await api.get<IJobResponse>('jobs', {
      params: {
        page,
      },
    });

    const data = response.data.data.map<IVagancy>((job) => ({
      id: job.id,
      title: job.title,
      created_at: format(parseISO(job.created_at), 'dd/MM/yyyy'),
      status: job.status as 'Em andamento' | 'Finalizado',
      nurseries: job.jobs_nurseries.map((jobsNursery) => jobsNursery.nursery),
    }));

    setTotalPages(response.data.last_page);
    setTotal(response.data.total);
    setVagancy(data);
  }, []);

  useEffect(() => {
    handleLoadJobs(pageSelected);
  }, [handleLoadJobs, pageSelected]);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<IOption>((nursery) => ({
          value: nursery.id,
          label: nursery.name,
          selected: false,
        }));
        setNurseries(data);
      });
  }, []);

  const handleVagancy = useCallback((index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setSelectedIndex(null);
    }
  }, []);

  useEffect(() => {
    if (selectedIndex !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedIndex, handleClickOutside]);

  const handleFocus = useCallback((e) => {
    if (e.target.parentNode.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.parentNode.classList.add('focused');
    } else if (e.target.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.classList.add('focused');
    }
  }, []);

  const handleBlur = useCallback((e) => {
    if (e.target.parentNode.parentNode.parentNode.tagName === 'LABEL') {
      const type =
        e.target.parentNode.parentNode.parentNode.getAttribute('aria-details');
      if (type === 'select') {
        setTimeout(() => {
          e.target.parentNode.parentNode.parentNode.classList.remove('focused');
        }, 300);
      } else {
        e.target.parentNode.parentNode.parentNode.classList.remove('focused');
      }
    } else if (e.target.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.classList.remove('focused');
    }
  }, []);

  const handleDelete = useCallback((job) => {
    Swal.fire({
      title: 'Deseja deletar essa vaga?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`jobs/${job.id}`);

          setVagancy((prevVagancy) =>
            prevVagancy.filter((vagancyData) => vagancyData.id !== job.id)
          );
          setTotal((oldState) => oldState - 1);

          Toast.fire({
            icon: 'success',
            title: 'Vaga deletada!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, []);

  const handleVagancySelected = useCallback(async (item, type?: string) => {
    const response = await api.get<IJob>(`jobs/${item.id}`, {
      params: {
        isAdmin: true,
      },
    });
    setNurseries((oldState) =>
      oldState.map((nursery) => ({
        ...nursery,
        selected: response.data.jobs_nurseries.some(
          (jobNursery) => jobNursery.nursery.id === nursery.value
        ),
      }))
    );
    setVagancySelected(response.data);
    setStatusSelected(response.data.status);
    if (type === 'visualizar') {
      setShowView(true);
    } else {
      setIsEdit(true);
      setShow(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowView(false);
    setVagancySelected({} as IJob);
    setStatusSelected('');
    setNurseries((oldState) =>
      oldState.map((nursery) => ({ ...nursery, selected: false }))
    );
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('A senha é obrigatória'),
          requirements: Yup.string().required('A senha é obrigatória'),
          description: Yup.string().required('A senha é obrigatória'),
          units: Yup.string().when('$unitsCheck', {
            is: (unitsCheck: boolean) => unitsCheck,
            then: Yup.string().required('Selecione pelo menos uma escola'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            unitsCheck: !nurseries.some((nursery) => nursery.selected),
          },
        });

        const { title, requirements, description } = data;

        const formData = {
          title,
          requirements,
          description,
        };

        const response = await api.post(`jobs`, formData);

        const nurseriesSelected = nurseries
          .filter((nursery) => nursery.selected)
          .map((nursery) => ({
            id: nursery.value as number,
            name: nursery.label,
          }));

        for (const nursery of nurseriesSelected) {
          const jobNurseryFormData = {
            job_id: response.data.id,
            nursery_id: nursery.id,
          };
          await api.post('jobs-nurseries', jobNurseryFormData);
        }

        setVagancy((oldState) => [
          {
            id: response.data.id,
            title,
            status: 'Em andamento',
            created_at: format(
              parseISO(response.data.created_at),
              'dd/MM/yyyy'
            ),
            nurseries: nurseriesSelected,
          },
          ...oldState,
        ]);
        setTotal((oldState) => oldState + 1);
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [handleClose, nurseries]
  );

  const handleSubmitUpdate = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('A senha é obrigatória'),
          requirements: Yup.string().required('A senha é obrigatória'),
          description: Yup.string().required('A senha é obrigatória'),
          units: Yup.string().when('$unitsCheck', {
            is: (unitsCheck: boolean) => unitsCheck,
            then: Yup.string().required('Selecione pelo menos uma escola'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            unitsCheck: !nurseries.some((nursery) => nursery.selected),
          },
        });

        const { title, requirements, description } = data;

        const formData = {
          title,
          requirements,
          description,
          status: vagancySelected.status,
        };

        const response = await api.put(`jobs/${vagancySelected.id}`, formData);

        const nurseriesSelected = nurseries
          .filter((nursery) => nursery.selected)
          .map((nursery) => ({
            id: nursery.value as number,
            name: nursery.label,
          }));

        const deletedNurseries = vagancySelected.jobs_nurseries.filter(
          (jobNursery) =>
            !nurseriesSelected.some(
              (nursery) => nursery.id === jobNursery.nursery.id
            )
        );

        const newNurseries = nurseriesSelected.filter(
          (nursery) =>
            !vagancySelected.jobs_nurseries.some(
              (jobNursery) => nursery.id === jobNursery.nursery.id
            )
        );

        for (const jobNursery of deletedNurseries) {
          await api.delete(`jobs-nurseries/${jobNursery.id}`);
        }

        for (const nursery of newNurseries) {
          const jobNurseryFormData = {
            job_id: response.data.id,
            nursery_id: nursery.id,
          };
          await api.post('jobs-nurseries', jobNurseryFormData);
        }

        const vagancyIndex = vagancy.findIndex(
          (vagancyData) => vagancyData.id === vagancySelected.id
        );

        if (vagancyIndex >= 0) {
          vagancy[vagancyIndex].title = title;
          vagancy[vagancyIndex].nurseries = nurseriesSelected;
        }

        setVagancy(vagancy);

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [handleClose, nurseries, vagancy, vagancySelected]
  );

  const handleClickDeleteResume = useCallback(
    (resume) => {
      Swal.fire({
        title: 'Deseja deletar esse currículo?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#f15a29',
        cancelButtonColor: '#a6a6a6',
        cancelButtonText: 'Não',
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`jobs-resumes/${resume.id}`);

            const resumes = vagancySelected.resumes.filter(
              (resumeData) => resumeData.id !== resume.id
            );

            setVagancySelected({
              ...vagancySelected,
              resumes,
            });

            Toast.fire({
              icon: 'success',
              title: 'Currículo deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [vagancySelected]
  );

  const handleSelectNuseries = useCallback((options) => {
    setNurseries(options);
  }, []);

  const handleChangeStatus = useCallback((options: IOption[]) => {
    const status = options.find((option) => option.selected);

    if (status) {
      setStatusSelected(status.value as string);
    }
  }, []);

  const handleSubmitStatus = useCallback(async () => {
    try {
      if (statusSelected) {
        const formData = {
          title: vagancySelected.title,
          requirements: vagancySelected.requirements,
          description: vagancySelected.description,
          status: statusSelected,
        };

        const response = await api.put(`jobs/${vagancySelected.id}`, formData);

        const vagancyIndex = vagancy.findIndex(
          (vagancyData) => vagancyData.id === vagancySelected.id
        );

        if (vagancyIndex >= 0) {
          vagancy[vagancyIndex].status = statusSelected as
            | 'Em andamento'
            | 'Finalizado';
        }

        setVagancy(vagancy);
      }

      handleClose();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
      }
    }
  }, [handleClose, statusSelected, vagancy, vagancySelected]);

  const handleClickPrev = useCallback(() => {
    setPageSelected((state) => (state - 1 <= 1 ? 1 : state - 1));
  }, []);

  const handleClickPage = useCallback((page) => {
    setPageSelected(page);
  }, []);

  const handleClickNext = useCallback(() => {
    setPageSelected((state) =>
      state + 1 >= totalPages ? totalPages : state + 1
    );
  }, [totalPages]);

  const pages = useMemo(() => {
    const qtd = width > 992 ? 7 : 5;
    const pageArray = Array.from(
      { length: totalPages < qtd ? totalPages : qtd },
      (e, index) => (totalPages < qtd ? `${index + 1}`.padStart(2, '0') : e)
    );

    const data = pageArray.map((page, index) => {
      if (page) {
        return page;
      }

      if (width > 992) {
        if (index === 0) {
          return '01';
        }
        if (index === 1) {
          if (pageSelected > 3 && pageSelected - 3 !== 1) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 4).toString().padStart(2, '0');
            }
            return (pageSelected - 1).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected > 4) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 3).toString().padStart(2, '0');
            }
            return pageSelected.toString().padStart(2, '0');
          }
          return (4).toString().padStart(2, '0');
        }
        if (index === 4) {
          if (pageSelected >= 5) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 2).toString().padStart(2, '0');
            }
            return (pageSelected + 1).toString().padStart(2, '0');
          }
          return (5).toString().padStart(2, '0');
        }
        if (index === 5) {
          if (pageSelected + 3 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 6) {
          return totalPages.toString().padStart(2, '0');
        }
      } else {
        if (index === 0) {
          return (1).toString().padStart(2, '0');
        }
        if (index === 1) {
          if (pageSelected > 3) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3 && pageSelected + 2 < totalPages) {
            return pageSelected.toString().padStart(2, '0');
          }
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 2).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 4) {
          return totalPages.toString().padStart(2, '0');
        }
      }

      return '';
    });

    return data as string[];
  }, [pageSelected, totalPages, width]);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5 mb-3 my-lg-5 d-lg-flex justify-content-between align-items-center">
            <h1 className="h3 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
              Trabalhe conosco
            </h1>
            <div className="d-flex align-items-center">
              <button
                type="button"
                onClick={() => {
                  setShow(true);
                  setIsEdit(false);
                }}
                className="btn-vagancy h6 fw-normal text-white rounded-pill mb-0"
              >
                <FiPlus size={22} color="#fff" className="me-2" />
                Abrir nova vaga
              </button>
              <div className="qtd h6 mb-0 fw-normal rounded-pill ms-3">
                Quantidade: <b className="fw-semibold">{total}</b> Processos
              </div>
            </div>
          </div>

          <div className="col-12">
            {vagancy.length > 0 ? (
              vagancy.map((item, index) => (
                <div className="vagancy-row d-flex justify-content-between align-items-center mb-3 position-relative">
                  <div className="d-flex align-items-center">
                    <div className="line me-3" />
                    <span className="h5 text-secondary mb-0 fw-normal">
                      {item.title}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="text-secondary h6 mb-0 fw-normal me-3">
                      Aberta: {item.created_at}
                    </span>
                    <span
                      className={`${
                        item.status === 'Finalizado'
                          ? 'finalized'
                          : 'in-progress'
                      } rounded-pill text-secondary h6 mb-0 me-3`}
                    >
                      {item.status}
                    </span>
                    {item.nurseries.map((nursery) => (
                      <span
                        key={nursery.id}
                        className={`${
                          nursery.name.includes('Mário Ito') ? 'ito' : 'bezerra'
                        } rounded-pill text-secondary h6 mb-0 me-3`}
                      >
                        {nursery.name}
                      </span>
                    ))}
                    <button
                      type="button"
                      onClick={() => handleVagancy(index)}
                      className="border-0 bg-transparent"
                    >
                      <BsThreeDotsVertical size={24} color="#707070" />
                    </button>
                  </div>
                  {selectedIndex === index && (
                    <div
                      ref={tooltipRef}
                      className="edit-delete d-flex flex-column align-items-center"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          handleVagancySelected(item, 'visualizar');
                        }}
                        className="d-flex align-items-center bg-transparent text-secondary border-bottom border-top-0 border-start-0 border-end-0 pb-1"
                      >
                        <RiEyeLine size={12} color="#717171" className="me-1" />
                        Vizualizar
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          handleVagancySelected(item);
                        }}
                        className="d-flex align-items-center bg-transparent text-secondary border-bottom border-top-0 border-start-0 border-end-0 py-1"
                      >
                        <TbEdit size={12} color="#717171" className="me-1" />
                        Editar
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDelete(item)}
                        className="d-flex align-items-center bg-transparent text-secondary border-0 pt-1"
                      >
                        <TbTrash size={12} color="#717171" className="me-1" />
                        Excluir
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="mb-4">
                <NoData message="Nenhum registro disponível" />
              </div>
            )}
          </div>

          <div className="col-12 d-flex justify-content-center mb-3">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-pagination arrow"
                onClick={handleClickPrev}
              >
                <RiArrowLeftSLine size={24} color="#707070" />
              </button>
              {pages.map((page, index) => (
                <button
                  key={index.toString()}
                  type="button"
                  className={`btn btn-pagination ${
                    page === pageSelected.toString().padStart(2, '0')
                      ? 'selected'
                      : ''
                  } ${page === '...' ? 'ellipsis' : ''}`}
                  onClick={() => handleClickPage(parseInt(page, 10))}
                  disabled={page === '...'}
                >
                  {page}
                </button>
              ))}
              <button
                type="button"
                className="btn btn-pagination arrow"
                onClick={handleClickNext}
              >
                <RiArrowRightSLine size={24} color="#707070" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="border-0 pt-lg-4 px-3 px-lg-5 mt-2 pb-0">
          <button
            type="button"
            className="ms-auto bg-transparent border-0"
            onClick={handleClose}
          >
            <MdClose size={40} color="#202020" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-3 px-lg-5 pt-0 mb-3">
          <Form
            ref={formRef}
            onSubmit={!isEdit ? handleSubmit : handleSubmitUpdate}
            className="box d-flex flex-wrap"
            initialData={vagancySelected}
          >
            <div className="w-100 px-3">
              <h2 className="h4 text-secondary fw-semibold mb-4 mb-lg-5">
                {isEdit ? 'Editar vaga' : 'Abrir Nova vaga'}
              </h2>
            </div>
            <div className="w-100 w-lg-50 mb-4 px-3">
              <label className="d-block w-100">
                <span>Nome da vaga</span>
                <Input
                  name="title"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </label>
            </div>
            <div className="w-100 w-lg-50  w-lg-50 mb-4">
              <label className="d-block w-100 px-3">
                <span>Exigências para o cargo:</span>
                <Input
                  type="text"
                  name="requirements"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </label>
            </div>

            <div className="w-100 mb-4 px-3">
              <label className="d-block w-100" aria-details="select">
                <span>Selecione a escola</span>
              </label>
              <InputCheckbox
                type="checkbox"
                name="units"
                className="input-checkbox"
                options={nurseries}
                onChange={handleSelectNuseries}
              />
            </div>
            <div className="w-100 mb-4 px-3">
              <label className="d-block w-100">
                <span>Descrição da vaga</span>
                <Textarea
                  rows={4}
                  name="description"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </label>
            </div>

            <div className="w-100 px-3">
              <button
                type="submit"
                className="btn btn-secondary rounded-pill mt-4 px-5 py-2 ms-auto d-block w-100 w-lg-auto"
              >
                {isEdit ? 'Editar vaga' : 'Criar vaga'}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 pb-0 pt-0 px-5 mb-2" />
      </Modal>

      <Modal show={showView} onHide={handleClose} size="lg">
        <Modal.Header className="border-0 pt-lg-4 px-3 px-lg-5 mt-2 pb-0">
          <button
            type="button"
            className="ms-auto bg-transparent border-0"
            onClick={handleClose}
          >
            <MdClose size={40} color="#202020" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-3 px-lg-5 pt-0 mb-3">
          {Object.keys(vagancySelected).length > 0 && (
            <Form
              onSubmit={handleSubmitStatus}
              className="box d-flex flex-wrap"
            >
              <div className="w-100 px-3">
                <h2 className="h4 text-secondary fw-semibold mb-4 mb-lg-5">
                  Visualizar vaga:{' '}
                  <span className="d-block fw-normal">
                    {vagancySelected.title}
                  </span>
                </h2>
                <div className="bg-status w-100 d-flex align-items-center">
                  <span className="h5 text-secondary fw-semibold mb-0 me-3">
                    Status da vaga:
                  </span>
                  <InputCheckbox
                    type="radio"
                    name="status"
                    className="input-radio"
                    options={[
                      {
                        label: 'Em andamento',
                        value: 'Em andamento',
                        selected: statusSelected === 'Em andamento',
                      },
                      {
                        label: 'Finalizado',
                        value: 'Finalizado',
                        selected: statusSelected === 'Finalizado',
                      },
                    ]}
                    onChange={handleChangeStatus}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary rounded-pill px-3 ms-auto"
                  >
                    Salvar
                  </button>
                </div>
                <span className="d-block h6 text-secondary fw-semibold pt-2 my-4">
                  Currículos: ({vagancySelected.resumes.length})
                </span>
                <div className="border-bottom px-3 py-3 w-100">
                  {vagancySelected.resumes.map((resume) => (
                    <div
                      key={resume.id}
                      className=" d-flex align-items-center justify-content-between mb-2"
                    >
                      <div className="d-flex align-items-center">
                        <GrDocument
                          size={32}
                          color="#E6883B"
                          className="me-3"
                        />
                        <span className="h5 text-secondary mb-0 ">
                          {resume.archive.name}
                        </span>
                      </div>
                      <div className="btns-group d-flex">
                        <a
                          href={resume.archive.archive_url}
                          className="me-3"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <RiEyeLine size={16} color="#fff" />
                        </a>
                        <button
                          type="button"
                          onClick={() => handleClickDeleteResume(resume)}
                        >
                          <TbTrash size={16} color="#fff" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 pb-0 pt-0 px-5 mb-2" />
      </Modal>
    </Container>
  );
};

export default WorkUs;
