import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container, Content } from './styles';
import SideNavbar from '~/components/SideNavbar';
import PoweredBy from '~/components/PoweredBy';

const DefaultLayout: React.FC = ({ children }) => {
  const { signOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    api.get('check-login').catch(() => {
      signOut();
    });
  }, [signOut, location.pathname]);

  return (
    <Container className="d-lg-flex justify-content-end">
      <SideNavbar />
      <Content>
        {children}
        <PoweredBy />
      </Content>
    </Container>
  );
};

export default DefaultLayout;
