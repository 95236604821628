import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  background: #f9f9f9;
  padding: 100px 0;

  h2 {
    color: #b4bccc;
  }
`;
