import React from 'react';

import { Container } from './styles';

import devsigner from '~/assets/logos/devsigner.svg';

interface IClass {
  className?: string;
}

const PoweredBy: React.FC<IClass> = ({ className }) => {
  return (
    <Container className={`${className} d-flex justify-content-center py-3`}>
      <small className="text-center text-lg-start mt-3 mt-lg-0">
        Desenvolvido por:{' '}
        <a
          href="https://devsigner.com.br/"
          target="_blank"
          className="text-white"
          rel="noreferrer"
        >
          <img src={devsigner} alt="Devsigner Systems" />
        </a>
      </small>
    </Container>
  );
};

export default PoweredBy;
