import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';

import noData from '~/assets/animations/no-data.json';

interface INoData {
  message: string;
}

const NoData: React.FC<INoData> = ({ message }) => {
  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <div className="pe-none">
        <Lottie
          options={{
            animationData: noData,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          width={206}
          height={176}
        />
      </div>
      <h2 className="text-center">{message}</h2>
    </Container>
  );
};

export default NoData;
